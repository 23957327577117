<template>
  <div class='modal-container' @click.prevent="$emit('close')">
    <div class='settings-modal' @click.stop>
      <div class='close' @click.stop="$emit('close')">
        <font-awesome-icon icon="fa-solid fa-xmark" />
      </div>
      <div class='heading'>Settings</div>
      <div class='settings'>
        <div class='setting-input'>
          <Checkbox v-model="showSpidercat" inputId="setting-spidercat" :binary="true" @change="updateSettings" />&nbsp;
          <label for='setting-spidercat'>Show Spidercat</label>
        </div>

        <div class='setting-input'>
          <Checkbox v-model="muteAudio" inputId="setting-audio" :binary="true" @change="updateSettings" />&nbsp;
          <label for='setting-audio'>Mute Audio</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Checkbox from 'primevue/checkbox';
import { ref, watch } from 'vue';

const props = defineProps({
  settings: {type: Object, required:  true}
})

const showSpidercat = ref(false);
const muteAudio = ref(false);

watch(() => props.settings, () => {
  showSpidercat.value = props.settings.showSpidercat;
  muteAudio.value = props.settings.muteAudio;
}, { immediate: true });

const emit = defineEmits(['close', 'updateSettings']);

const updateSettings = () => {
  emit('updateSettings', {
    showSpidercat: showSpidercat.value,
    muteAudio: muteAudio.value,
  });
};

</script>

<style scoped>

.modal-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  background: #444444aa;
}

.close {
  cursor:pointer;
  position:absolute;
  top: 0;
  right: 0;

  padding: 8px 10px;
  font-weight: bold;
  font-size: 22px;
  color: #151515;

}

.settings-modal {
  position: relative;

  width: 80%;
  max-width: 500px;
  height: 200px;
  margin-top: 10vh;

  border: 1px solid #ddd;
  background: #fefefe;
  border-radius: 4px;
}

.heading {
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  padding: 10px 15px;
}

.settings {
  padding: 15px;
}

.setting-input {
  padding: 10px 0;
}

</style>
