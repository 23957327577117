<template>
  <div class='main' @click="hideMenu" @contextmenu.prevent="showMenu">
    <div class='canvasContainer' :style="{width: `${size}px`, height: `${size}px`}">
      <div v-show="isAuthed">
        <canvas ref="canvasRef"
                @click.exact.left="click"
                @click.shift.prevent="reset"
                @click.exact.right.prevent="showMenu"
                @contextmenu.prevent="showMenu"
        />
        <img v-if="settings.showSpidercat" src='/lick.gif' alt=''>
      </div>
      <div class='login-prompt' v-if="!isAuthed">
        <div>Please Login with Twitch</div>
        <a :href="`https://id.twitch.tv/oauth2/authorize?client_id=${clientId}&response_type=token&scope=user:read:follows+channel:manage:raids&redirect_uri=${redirectUri}`">Login</a>
      </div>
      <div v-show="contextMenuTop || contextMenuLeft" ref="contextMenuRef" class='contextMenu'
           :style="{top: `${contextMenuTop}px`, left: `${contextMenuLeft}px`}">
        <template v-if="contextMenuTop || contextMenuLeft">
          <label>{{ contextItem.user_name || 'Actions' }}</label>
          <ul>
            <li v-if="contextItem.user_name" @click="removeItem">
              <font-awesome-icon icon="fa-solid fa-xmark" fixed-width />
              Remove
            </li>
            <li v-if="contextItem.user_name" @click="excludeItem">
              <font-awesome-icon icon="fa-solid fa-filter-circle-xmark" fixed-width />
              Exclude
            </li>
            <template v-if="!contextItem.user_name">
              <li @click="reload">
                <font-awesome-icon icon="fa-solid fa-rotate-right" fixed-width />
                Reload
              </li>
              <li @click="$emit('show-settings')">
                <font-awesome-icon icon="fa-solid fa-sliders" fixed-width />
                Settings
              </li>
              <li @click="$emit('show-filters')">
                <font-awesome-icon icon="fa-solid fa-filter" fixed-width />
                Filters
              </li>
            </template>
          </ul>
        </template>
      </div>
    </div>
    <template v-if="canvasRef">
    </template>
    <div class='buttonContainer' v-if="selectedSlice && !rotating">

      <div>
        <h2>
          <a :href="`https://twitch.tv/${selectedSlice.user_name.toLowerCase()}`" target=_blank>
            {{ selectedSlice.user_name }}
            <span class='icon'>
              <font-awesome-icon icon="fa-solid fa-arrow-up-right-from-square" size="md"/>
          </span>
          </a>
        </h2>
        <div>{{ selectedSlice.viewer_count }} {{ selectedSlice.viewer_count === 1 ? 'Viewer' : 'Viewers' }}</div>
        <div>{{ selectedSlice.game_name }}&nbsp;</div>
      </div>

      <div>
        <ButtonElement @click="triggerRaid">
          <template v-if="!raidActive">
            <font-awesome-icon icon="fa-solid fa-rocket"/>
          </template>
          <template v-else>
            <font-awesome-icon icon="fa-solid fa-ban"/>
          </template>
          {{ !raidActive ? 'Start' : 'Cancel' }} Raid
        </ButtonElement>
      </div>

      <div style='flex-basis: 100%;'>{{ selectedSlice.title }}&nbsp;</div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import ButtonElement from '@/components/ButtonElement';

export default {
  name: 'SpinningWheel',
  components: { ButtonElement },
  props: { settings: { type: Object, required: true }, filters: { type: Object, required: true }, modalOpen: {type: Boolean, default: false} },

  emits: ['show-settings', 'show-filters', 'exclude-channel'],

  setup: (props, {emit}) => {

    watch(() => props.settings, () => {
      reset(false);
    })

    const clientId = process.env.VUE_APP_CLIENT_ID;
    const redirectUri = window.location.origin;

    const canvasRef = ref(null);
    const rotationAngle = ref(0);
    const rotationSpeed = ref(0);

    const height = ref(window.innerHeight);
    const width = ref(window.innerWidth);
    const size = ref(Math.min(width.value, height.value));

    const contextItem = ref(null);

    const accessToken = ref(null);
    const user = ref(null);

    const clickAudio = new Audio('/click.wav');

    const _ctx = ref(null);
    const getCtx = () => {
      if (!_ctx.value) {
        _ctx.value = canvasRef.value.getContext('2d');
      }

      return _ctx.value;
    };

    const resetCanvas = () => {
      const ctx = getCtx();
      if (ctx.reset) {
        ctx.reset();
      } else {
        ctx.clearRect(0, 0, canvasRef.value.width, canvasRef.value.height);
      }
    };

    const loadAvatar = () => {
      if (!avatar.src) {
        avatar.src = user.value?.profile_image_url || '';
      }

      const ctx = getCtx();
      const avatarSize = radius.value * 0.4;

      ctx.save();
      ctx.beginPath();
      ctx.arc(centreX.value, centreY.value, avatarSize / 2, 0, 360, false);
      ctx.closePath();
      ctx.clip();
      ctx.drawImage(avatar, centreX.value - avatarSize / 2, centreY.value - avatarSize / 2, avatarSize, avatarSize);
      ctx.restore();
    };

    const avatar = new Image();
    avatar.onload = loadAvatar;

    const colors = ['#4CAF50', '#E91E63', '#00BCD4', '#FFC107', '#800080'];

    const removedSlices = ref([]);
    const availableSlices = ref(JSON.parse(localStorage.getItem('liveChannels')) || []);

    const slices = computed(() => {
      return availableSlices.value.filter(slice => {
        const range = props.filters.viewerCount;
        const content = props.filters.content;
        const excludedCategories = (props.filters.excludedCategories || []).map(c => c.toLowerCase());
        const excludedChannels = (props.filters.excludedChannels || []).map(c => c.toLowerCase());


        const inViewerRange = slice.viewer_count >= range[0] && slice.viewer_count <= range[1] || (slice.viewer_count > range[1] && range[1] === 1000);
        const allowedContent = content === 'any' || (content === 'only-mature' && slice.is_mature) || (content === 'exclude-mature' && !slice.is_mature);
        const excludeCategory = excludedCategories.includes(slice.game_name.toLowerCase());
        const excludeChannel = excludedChannels.includes(slice.user_name.toLowerCase());

        const removedSlice = removedSlices.value.includes(slice.user_id);

        return inViewerRange && allowedContent && !excludeCategory && !excludeChannel && !removedSlice;
      });
    });

    watch(slices, () => {
      reflow();
      if(!slices.value.length || selectedSlice.value) {
        reset();
      }
    })

    const selectedSlice = computed(() => {
      const currentAngle = rotationAngle.value * 180 / Math.PI;
      const angle = 360 / slices.value.length;
      return slices.value[slices.value.length - Math.ceil((currentAngle / angle) % slices.value.length)];
    });

    const centreX = ref(0);
    const centreY = ref(0);
    const radius = ref(0);

    const drawBg = () => {
      const ctx = getCtx();

      ctx.save();
      ctx.fillStyle = '#ccc';
      ctx.shadowColor = '#666';
      ctx.shadowBlur = 10;

      ctx.beginPath();
      ctx.moveTo(centreX.value, centreY.value);
      ctx.arc(centreX.value, centreY.value, radius.value, 0, 360, false);
      ctx.closePath();
      ctx.fill();

      ctx.restore();

    };

    const drawFg = () => {
      const ctx = getCtx();

      ctx.save();

      ctx.fillStyle = '#fff';
      ctx.shadowColor = '#999';
      ctx.shadowBlur = 4;

      // ctx.beginPath();
      // ctx.moveTo(centreX.value, centreY.value);
      // ctx.arc(centreX.value, centreY.value, radius.value * .2, 0, 360, false);
      // ctx.closePath();
      // ctx.fill();

      ctx.restore();

      if (user.value?.profile_image_url) {
        loadAvatar();
      }

      if (!props.settings.showSpidercat) {
        ctx.save();
        ctx.fillStyle = '#fff';
        ctx.shadowColor = '#000';
        ctx.shadowBlur = 5;

        ctx.beginPath();
        const size = radius.value / 12;
        ctx.moveTo(centreX.value + radius.value - size, centreY.value);
        ctx.lineTo(centreX.value + radius.value - size, centreY.value);
        ctx.lineTo(centreX.value + radius.value + size * 0.25, centreY.value - size * 0.75);
        ctx.lineTo(centreX.value + radius.value + size * 0.25, centreY.value + size * 0.75);
        ctx.lineTo(centreX.value + radius.value - size, centreY.value);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
      }

    };

    const draw = () => {
      const ctx = getCtx();

      if (!slices.value) {
        return;
      }

      const angle = (1 / slices.value.length) * 2 * Math.PI;

      // Iterate through the angles
      for (let i = 0; i < slices.value.length; i = i + 1) {
        const beginAngle = angle * i;
        const endAngle = angle * (i + 1);

        const isLastSlice = i + 1 === slices.value.length && slices.value.length > 1;
        const colorIndex = isLastSlice && i % colors.length === 0 ? (i + 1) % colors.length : i % colors.length;
        const color = colors[colorIndex];

        ctx.save();
        ctx.strokeStyle = color;
        ctx.fillStyle = color;

        ctx.beginPath();
        ctx.moveTo(centreX.value, centreY.value);
        ctx.arc(centreX.value, centreY.value, radius.value, beginAngle, endAngle, false);
        ctx.lineTo(centreX.value, centreY.value);

        // ctx.arcTo(centreX.value, centreY.value, centreX.value, centreY.value, radius.value)

        ctx.closePath();
        // ctx.stroke();
        ctx.fill();
        ctx.restore();

        ctx.save();

        const text = slices.value[i].user_name;
        // const ratio = pixelRatio();
        let fontSize = canvasRef.value.width / Math.max(20, slices.value.length - 2);
        ctx.font = `bold ${fontSize}px Arial`;
        let { width: textWidth } = ctx.measureText(text);

        const buffer = (canvasRef.value.width / 22);

        while (textWidth > radius.value - 3 * buffer) {
          fontSize--;
          ctx.font = `bold ${fontSize}px Arial`;
          textWidth = ctx.measureText(text).width;
        }

        const labelX = centreX.value;
        const labelY = centreY.value;

        ctx.translate(labelX, labelY);
        ctx.rotate((angle * (i + 1)) - (angle / 2));
        ctx.translate(-labelX, -labelY);

        ctx.fillStyle = '#fff';
        ctx.textAlign = 'left';
        const maxLength = radius.value - (buffer);
        const leftBuffer = buffer * 2.5;
        ctx.fillText(text, labelX + Math.max(leftBuffer, maxLength - textWidth), labelY + (fontSize / 3), maxLength);

        ctx.restore();

      }
    };

    const raf = window.mozRequestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        window.oRequestAnimationFrame || window.requestAnimationFrame;

    const pixelRatio = () => {
      const ctx = getCtx();

      const dpr = window.devicePixelRatio || 1;
      const bsr = ctx.webkitBackingStorePixelRatio || ctx.mozBackingStorePixelRatio || ctx.msBackingStorePixelRatio ||
          ctx.oBackingStorePixelRatio || ctx.backingStorePixelRatio || 1;

      return Math.max(0, dpr / bsr);
    };

    const reflow = () => {
      height.value = window.innerHeight - 150;
      width.value = window.innerWidth;
      size.value = Math.min(height.value, width.value);

      let ratio = pixelRatio();

      centreX.value = size.value / 2 * ratio;
      centreY.value = size.value / 2 * ratio;
      // radius.value = (size.value / 2 * ratio) - 20;
      radius.value = Math.floor(size.value / 2 * ratio) - 20;

      canvasRef.value.height = size.value * ratio;
      canvasRef.value.width = size.value * ratio;

      canvasRef.value.style.height = size.value;
      canvasRef.value.style.width = size.value;

      // const ctx = getCtx();
      // ctx.setTransform(ratio, 0, 0, ratio, 0, 0);
      // ctx.save();

      reset(false);
    };

    const loadUser = () => {
      fetch('https://api.twitch.tv/helix/users', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken.value}`,
          'Client-ID': clientId,
        },
      }).then(async response => {

        if (!response.ok) {
          return clearData();
        }

        const data = await response.json();
        const users = data.data;
        user.value = users[0];
        localStorage.setItem('user', JSON.stringify(users[0]));

        reset(false);

        const loadLive = true;

        if (loadLive) {
          loadLiveFollows();
          setInterval(loadLiveFollows, 60 * 1000)

        } else {
          loadFollows();
        }
      });
    };

    const loadFollows = () => {
      fetch(`https://api.twitch.tv/helix/users/follows?from_id=${user.value.id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken.value}`,
          'Client-ID': clientId,
        },
      }).then(async response => {
        const data = await response.json();
        const channels = data.data;

        const channelList = [];
        for (let channel of channels) {
          channelList.push(channel.to_name);
        }

        localStorage.setItem('liveChannels', JSON.stringify(channelList));
        reset(false);
      });
    };

    const loadLiveFollows = () => {

      if(rotating.value || selectedSlice.value) {
        return false;
      }

      fetch(`https://api.twitch.tv/helix/streams/followed?user_id=${user.value.id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken.value}`,
          'Client-ID': clientId,
        },
      }).then(async response => {
        if (!response.ok) {
          return clearData();
        }

        const data = await response.json();
        const channels = data.data;

        const channelList = [];
        for (let channel of channels) {
          if (channel.user_name) {
            channelList.push(channel);
          }
        }

        localStorage.setItem('liveChannels', JSON.stringify(channelList));
        availableSlices.value = channelList;
        reset(false);
      });
    };

    const clearData = () => {
      localStorage.removeItem('user');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('liveChannels');
      user.value = null;
      reset();
      return null;
    };

    onMounted(() => {
      console.log('Version:', process.env.VUE_APP_VERSION);
      window.addEventListener('resize', () => reflow());
      reflow();

      window.addEventListener('keyup', (e) => {
        if (!props.modalOpen && (e.key === ' ' || e.code === 'Space')) {
          click();
        }
      });

      const hash = window.location.hash.substring(1);
      const params = {};
      hash.split('&').map(hk => {
        const temp = hk.split('=');
        params[temp[0]] = decodeURIComponent(temp[1]);
      });

      accessToken.value = params?.access_token || localStorage.getItem('accessToken');
      user.value = JSON.parse(localStorage.getItem('user'));
      localStorage.setItem('accessToken', accessToken.value);

      location.hash = '';

      reset();
      loadUser();
    });

    onUnmounted(() => {
      window.removeEventListener('resize', () => reflow());
    });

    let rotating = ref(false);

    const reload = () => {
      loadUser();
      reset();
    };

    const reset = (resetPosition = true) => {
      resetCanvas();

      drawBg();
      draw();
      drawFg();

      if (resetPosition) {
        rotating.value = false;
        rotationSpeed.value = 0;
        rotationAngle.value = 0;
      } else {
        const ctx = getCtx();
        resetCanvas();
        drawBg();

        ctx.save();
        ctx.translate(centreX.value, centreY.value);
        ctx.rotate(rotationAngle.value);
        ctx.translate(-centreX.value, -centreY.value);

        draw();
        ctx.restore();
        drawFg();
      }
    };

    const rotate = () => {
      const ctx = getCtx();

      if (!rotating.value) {
        // draw();
        return false;
      }

      raf(rotate);

      // rotationAngle.value += 2 * Math.PI / 180;
      // console.log  (rotationAngle.value);

      rotationAngle.value += rotationSpeed.value;

      resetCanvas();
      drawBg();

      ctx.save();
      ctx.translate(centreX.value, centreY.value);
      ctx.rotate(rotationAngle.value);
      ctx.translate(-centreX.value, -centreY.value);

      draw();
      ctx.restore();
      drawFg();
    };

    const isAccelerating = ref(false);

    let slowDownTimeout = null;

    const slowDown = (force = false) => {
      clearTimeout(slowDownTimeout);
      const skip = isAccelerating.value || Math.random() < 0.01 * Math.min(slices.value.length, 10);

      if (rotating.value && (!skip || force)) {
        rotationSpeed.value -= Math.max(rotationSpeed.value * Math.random() / 80, 0.00003);
        rotating.value = rotationSpeed.value > 0;
      }
      slowDownTimeout = setTimeout(() => slowDown(true), 20);
    };

    let timeout;
    watch(selectedSlice, () => {
      if (rotating.value && !props.settings.muteAudio) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          clickAudio.currentTime = 0.003;
          clickAudio.play();
        }, 3);
        slowDown();
      }
    });

    const raidActive = ref(false);

    const triggerRaid = (e) => {
      const channelId = selectedSlice.value?.user_id;
      const action = raidActive.value ? 'DELETE' : 'POST';
      raidActive.value = !raidActive.value;

      if (channelId) {
        fetch(
            `https://api.twitch.tv/helix/raids?broadcaster_id=${user.value.id}&from_broadcaster_id=${user.value.id}&to_broadcaster_id=${channelId}`,
            {
              method: action,
              headers: {
                'Authorization': `Bearer ${accessToken.value}`,
                'Client-ID': clientId,
              },
            }).then(async response => {
          e.blur();
          if (!response.ok) {
            alert('Error!');
          }
        });
      }

    };

    const accelerationRange = [0.5, 0.4, 0.3];

    const speedUp = () => {
      const acceleration = accelerationRange[Math.ceil(slices.value.length / 10)] || 0.2;
      if (isAccelerating.value && rotationSpeed.value < acceleration) {
        rotationSpeed.value += Math.min(Math.random() / 80, 0.01);
        setTimeout(() => speedUp(), 10);
      } else {
        isAccelerating.value = false;
        slowDown();
      }
    };

    const click = () => {
      const overrideCheck = false;
      if ((!rotating.value && slices.value.length) || overrideCheck) {
        rotating.value = !rotating.value;
        rotationSpeed.value = 0.0;
        isAccelerating.value = true;
        speedUp();
        rotate();
      }
    };

    const contextMenuRef = ref(null);
    const contextMenuTop = ref(0);
    const contextMenuLeft = ref(0);

    const hideMenu = () => {
      contextMenuTop.value = 0;
      contextMenuLeft.value = 0;
    };

    const showMenu = (e) => {
      contextItem.value = null;
      if (!rotating.value) {
        const containerBounds = canvasRef.value.offsetParent.getBoundingClientRect();
        const cx = (e.x - containerBounds.x) * pixelRatio();
        const cy = (e.y - containerBounds.y) * pixelRatio();

        const ctx = getCtx();
        const angle = (1 / slices.value.length) * 2 * Math.PI;
        for (let i = 0; i < slices.value.length; i = i + 1) {
          const beginAngle = angle * i;
          const endAngle = angle * (i + 1);

          ctx.save();

          ctx.translate(centreX.value, centreY.value);
          ctx.rotate(rotationAngle.value);
          ctx.translate(-centreX.value, -centreY.value);

          // console.log(e.x, e.y, containerBounds);

          // ctx.beginPath();
          // ctx.arc(cx, cy, 10, 0, 360, false);
          // ctx.fillStyle = '#fff';
          // ctx.fill();
          // ctx.closePath();

          ctx.beginPath();
          ctx.moveTo(centreX.value, centreY.value);
          ctx.arc(centreX.value, centreY.value, radius.value, beginAngle, endAngle, false);
          const intersecting = ctx.isPointInPath(cx, cy);
          ctx.closePath();
          ctx.restore();

          if (intersecting) {
            contextItem.value = slices.value[i];
            positionContextMenu(e.pageX, e.pageY);
          }
        }
      }

      if (!contextItem.value) {
        contextItem.value = { user_name: '' };
        positionContextMenu(e.pageX, e.pageY);
      }
    };

    const positionContextMenu = (x, y) => {
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      const height = contextMenuRef.value.clientHeight || 0;
      const width = contextMenuRef.value.clientWidth || 0;

      contextMenuTop.value = y + height < windowHeight ? y : windowHeight - height;
      contextMenuLeft.value = x + width < windowWidth ? x : windowWidth - width;
    };

    const removeItem = () => {
      removedSlices.value.push(contextItem.value.user_id);
      reset(false);
    };

    const excludeItem = () => {
      emit('exclude-channel', contextItem.value.user_name);
      reset(false);
    }

    return {
      pixelRatio,
      canvasRef,
      contextItem,
      clientId,
      redirectUri,
      isAuthed: computed(() => !!user.value?.id),
      size,
      contextMenuRef,
      contextMenuTop,
      contextMenuLeft,
      click,
      reset,
      reload,
      showMenu,
      hideMenu,
      triggerRaid,
      raidActive,
      selectedSlice,
      rotating,
      removeItem,
      excludeItem
    };

  },

};
</script>

<style scoped>

.main {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

div {
  user-select: none;
  font-family: 'Arial', sans-serif;
  text-align: center;
}

.canvasContainer, .buttonContainer {
  position: relative;
  margin: 0 auto;
  text-align: center;
}

.buttonContainer {
  position: relative;
  width: 100%;
  top: 0;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.buttonContainer > div {
  margin: 15px;
}

.contextMenu {
  z-index: 1000;
  position: fixed;
  border: 1px solid #ddd;
  background: #fefefe;
  min-width: 150px;
  border-radius: 4px;
  max-width: 180px;
}

.login-prompt {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

label {
  display: block;
  text-align: center;
  font-weight: bold;
  padding: 5px 10px;
  background: #ddd;
  border-bottom: 1px solid #ccc;
  overflow: hidden;
  text-overflow: ellipsis;
}

ul {
  text-align: left;
  list-style: none;
  text-indent: 0;
  margin: 0;
  padding-inline: 0;
}

li {
  cursor: pointer;
  padding: 10px;
  white-space: nowrap;
}

li:hover {
  background: #efefef;
}

h2 {
  white-space: break-spaces;
  word-break: break-word;
  margin: 0;
}

h2 .icon {
  font-size: 16px;
  vertical-align: middle;
}

img {
  position: absolute;
  top: calc(50% - 37px);
  right: -5px;
  width: 60px;
  height: 60px;
}

a, a:visited, a:active {
  color: #222;
  text-decoration: none;
}

a:hover {
  color: #444;
}

canvas {
  width: 100%;
}
</style>
