<template>
  <button @click="click">
    <slot/>
  </button>
</template>

<script>
export default {
  name: 'ButtonElement',
  props: {
    text: String,
  },

  setup: () => {

  },

};
</script>

<style scoped>

@keyframes shake {
  0% { transform: translateX(0) }
  25% { transform: translateX(2px) }
  50% { transform: translateX(-2px) }
  75% { transform: translateX(2px) }
  100% { transform: translateX(0) }
}

button {
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 3px;

  background: #efefef;
  border: 1px solid #999;

  font-size: 18px;
  font-family: 'Arial', sans-serif;
  font-variant: small-caps;
}

button:hover {
  background: #fefefe;
  border-color: #777;
  animation: shake 200ms;
  animation-iteration-count: infinite;
}

</style>
