<template>
  <SpinningWheel @show-settings="showSettings = true" @show-filters="showFilters = true"
                 @exclude-channel="excludeChannel" :filters="filters" :settings="settings" :modal-open="showFilters || showSettings" />
  <SettingsModal v-if="showSettings" @close="showSettings = false" @update-settings="updateSettings" :settings="settings"/>
  <FiltersModal v-if="showFilters" @close="showFilters = false" @update-filters="updateFilters" :filters="filters"/>
</template>

<script setup>
import SpinningWheel from './components/SpinningWheel.vue';
import SettingsModal from '@/components/SettingsModal';
import { ref } from 'vue';
import FiltersModal from '@/components/FiltersModal.vue';

const showSettings = ref(false);
const showFilters = ref(false);

const defaultFilters = { viewerCount: [0, 1000], content: 'any', excludedCategories: [], excludedChannels: [] };
const defaultSettings = { showSpidercat: true, muteAudio: false };

const filters = ref(JSON.parse(localStorage.getItem('filters')) || defaultFilters);
const settings = ref(JSON.parse(localStorage.getItem('settings')) || defaultSettings);

const excludeChannel = (channel) => {
  const newFilters = {...filters.value};
  newFilters.excludedChannels = [...filters.value.excludedChannels, channel];

  updateFilters(newFilters);
}

const updateSettings = (value) => {
  settings.value = value;
  localStorage.setItem('settings', JSON.stringify(value));
}

const updateFilters = (value) => {
  filters.value = value;
  localStorage.setItem('filters', JSON.stringify(value));
}

</script>

<style>
@import '@fortawesome/fontawesome-svg-core/styles.css';

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  color: #222;
}

* {
  box-sizing: border-box;
}


body {
  background: #efefef;
}
</style>
