import { createApp } from 'vue';
import App from './App.vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/soho-light/theme.css';

library.add(fas);

const app = createApp(App);

app.use(PrimeVue);

app.component('font-awesome-icon', FontAwesomeIcon, {});

app.mount('#app');

