<template>
  <div class='modal-container' @click.prevent>
    <div class='filters-modal' @click.stop>
      <div class='close' @click.stop="$emit('close')">
        <font-awesome-icon icon="fa-solid fa-xmark"/>
      </div>
      <div class='heading'>Filters</div>
      <div class='filters'>

        <div class='filter-input'>
          <label for='filter-viewer-count'>Viewer Count:
            <span>{{ viewerCount[0] }} - {{ viewerCount[1] === 1000 ? '1000+' : viewerCount[1] }}</span>
          </label>
          <Slider id='filter-viewer-count' v-model="viewerCount" range class="slider" :min=0 :max=1000 :step=10
                  @change="updateFilters"/>
        </div>

        <div class='filter-input'>
          <label for='filter-mature-content'>Content Classification:</label>
          <Dropdown v-model="content" :options="[{label: 'Any Content', value: 'any'}, {label: 'Only Mature Content', value: 'only-mature'}, {label: 'Exclude Mature Content', value: 'exclude-mature'}]" optionLabel="label" optionValue="value" @change="updateFilters" />
        </div>

        <div class='filter-input'>
          <label for='filter-categories'>Exclude Categories:</label>
          <Chips v-model="excludedCategories" separator="," :allow-duplicate=false @add="addCategory" @remove="updateFilters" />
        </div>

        <div class='filter-input'>
          <label for='filter-channels'>Exclude Channels:</label>
          <Chips v-model="excludedChannels" separator="," :allow-duplicate=false @add="addChannel" @remove="updateFilters" class="chip" />
        </div>



      </div>
    </div>
  </div>
</template>

<script setup>

import Dropdown from 'primevue/dropdown';
import Slider from 'primevue/slider';
import Chips from 'primevue/chips';

import { ref, watch } from 'vue';

const viewerCount = ref([]);
const content = ref('any');
const excludedCategories = ref([]);
const excludedChannels = ref([]);

const emit = defineEmits(['close', 'updateFilters']);

const props = defineProps({
  filters: {type: Object, required:  true}
})

watch(() => props.filters, () => {
  viewerCount.value = props.filters.viewerCount;
  content.value = props.filters.content;
  excludedCategories.value = props.filters.excludedCategories;
  excludedChannels.value = props.filters.excludedChannels;
}, { immediate: true });


const addCategory = (category) => {
  const clientId = process.env.VUE_APP_CLIENT_ID;
  const accessToken = localStorage.getItem('accessToken');

  const params = new URLSearchParams({
    query: category.value.slice(-1),
    first: 1,
  });

  fetch(`https://api.twitch.tv/helix/search/categories?${params}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Client-ID': clientId,
    },

  }).then(async response => {
    const { data } = await response.json();
    if(data.length) {
      excludedCategories.value.splice(-1, 1, data[0].name);
      excludedCategories.value = [...new Set(excludedCategories.value)];
      updateFilters();
    } else {
      excludedCategories.value.splice(-1, 1);
      updateFilters();
    }
  });
}

const addChannel = (channel) => {
  const clientId = process.env.VUE_APP_CLIENT_ID;
  const accessToken = localStorage.getItem('accessToken');

  const params = new URLSearchParams({
    query: channel.value.slice(-1),
    first: 1,
  });

  fetch(`https://api.twitch.tv/helix/search/channels?${params}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Client-ID': clientId,
    },

  }).then(async response => {
    const { data } = await response.json();
    if(data.length) {
      excludedChannels.value.splice(-1, 1, data[0].display_name);
      excludedChannels.value = [...new Set(excludedChannels.value)];
      updateFilters();
    } else {
      excludedChannels.value.splice(-1, 1);
      updateFilters();
    }
  });
}

const updateFilters = () => {
  emit('updateFilters', {
    viewerCount: viewerCount.value,
    content: content.value,
    excludedCategories: excludedCategories.value.filter(c => c.length >= 1 && c.length <= 64),
    excludedChannels: excludedChannels.value.filter(c => c.length >= 4 && c.length <= 25),
  });
};


</script>

<style scoped>

.slider {
  flex: 230px 1 1;
  margin: 10px;
}

:deep(.p-chips) {
  flex: 100% 1 0;
  margin: 10px 0;
  display: block;
}

.modal-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  background: #444444aa;
}

.close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;

  padding: 8px 10px;
  font-weight: bold;
  font-size: 22px;
  color: #151515;

}

.filters-modal {
  position: relative;

  width: 80%;
  max-width: 500px;
  height: auto;
  margin-top: 10vh;

  border: 1px solid #ddd;
  background: #fefefe;
  border-radius: 4px;
}

.heading {
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  padding: 10px 15px;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  max-height: 75vh;
  overflow-x: hidden;
  overflow-y: auto;

  > div {
    margin: 10px 0;
    flex-basis: 100%;
  }
}

label {
  align-self: center;
  width: 200px;

  > span {
    font-weight: bold;
    color: #7254f3;
  }
}

.filter-input {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
}

</style>
